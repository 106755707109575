html,
body {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.version {
  position: absolute;
  font-size: 15px;
  color: gray;
  bottom: 26px;
  text-align: center;
}

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.github {
  cursor: pointer;
  background-repeat: no-repeat;
  position: absolute;
  background-image: url("./assets/github.png");
  background-size: cover;
  display: block;
  width: 46px;
  height: 46px;
  margin: 4px;
  border-radius: 28px;
  display: inline-block;
  right: 55px;
  top: 25px;
  z-index: 1999;
  transform: translateY(-20px);
}

.github::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 50%;
  box-shadow: inset 0 0 10px #fff06a, inset 4px 0 16px #f0f, inset -4px 0 16px #0ff, inset 4px 0 16px #f0f, inset -4px 0 16px #0ff, 0 0 10px #fff06a, -6px 0 36px #f0f, 6px 0 36px #0ff;
  -webkit-animation: rotate 3s linear infinite;
  animation: rotate 3s linear infinite;
}

#root {
  height: 100%;
  width: 100%;
  display: flex;
  background: #fafafa;
}

.margin-right-19 {
  margin-right: 19px;
}

.btn-container {
  width: 52px;
  height: 52px;
  border-radius: 30px;
  background-color: #000;
  opacity: 0.4;
}

.meeting {
  display: flex;
  flex: 1;
}

.current-view {
  display: flex;
  flex: 1;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #666;
  background-size: 280px;
  background-image: url('./assets/logo-open-live-gray.png');
}

.stream-player {
  display: flex;
  flex: 1;
}

.stream-container {
  left: 30px;
  top: 91px;
  height: 660px;
  width: 233px;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  z-index: 2;
}

.stream-container > .stream-player {
  max-height: 150px;
  background: rgba(216, 216, 216, 1);
  max-width: 200px;
  margin: 4px 0;
  position: relative;
  border-radius: 2px;
  overflow: hidden;
}

.avatar-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 44px;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 24px;
  flex-direction: row;
  box-sizing: border-box;
  width: 200px;
  margin-left: 30px;
  margin-top: 30px;
}

.default-avatar {
  background-color: #fff de;
  background-image: url("./assets/avatar.png");
  background-size: 38px;
  display: block;
  width: 38px;
  height: 38px;
  margin: 3px;
  margin-left: 0;
  border-radius: 28px;
}

.autoplay {
  cursor: pointer;
}

.avatar-text {
  display: flex;
  justify-content: center;
  border-radius: 28px;
  width: 100px;
  height: 20px;
  align-items: center;
  border-radius: 10px;
  font-size: 16px;
  font-family: 'HelveticaNeue';
  color: rgba(255, 255, 255, 1);
  line-height: 14px;
}

.nav {
  position: absolute;
  display: flex;
  width: 100%;
  top: 0;
  left: 0;
  justify-content: space-between;
  z-index: 3;
}

.quit {
  display: block;
  margin-top: 36px;
  margin-right: 36px;
  background-image: url("./assets/icon-exit-hover.png");
  background-size: 32px;
  width: 32px;
  height: 32px;
  border-radius: 30px;
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
}

.quit:hover {
  background-color: #44a2fc;
  opacity: 1;
}

.like {
  cursor: pointer;
  background-image: url("./assets/icon-add-blue.png");
  background-size: 30px;
  display: block;
  width: 30px;
  height: 30px;
  margin: 4px;
  border-radius: 28px;
}

.like:hover {
  background-image: url("./assets/icon-add-blue.png");
}

.stream-uid {
  padding: 2px 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 10px;
  left: 5px;
  bottom: 7px;
  z-index: 2;
  position: absolute;
  border-radius: 28px;
  max-width: 100px;
  max-height: 20px;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  font-size: 12px;
  font-family: HelveticaNeue;
  color: rgba(255, 255, 255, 1);
  line-height: 14px;
}

.mute-video {
  background-image: url("./assets/icon-camera.png");
}

.mute-video:hover {
  background-color: #44a2fc;
  opacity: 1;
}

.unmute-video {
  background-image: url("./assets/icon-camera-off.png");
}

.unmute-video:hover {
  background-color: #44a2fc;
  opacity: 1;
}

.mute-audio {
  background-image: url("./assets/icon-microphone.png");
}

.mute-audio:hover {
  background-color: #44a2fc;
  opacity: 1;
}

.unmute-audio {
  background-image: url("./assets/icon-microphone-off.png");
}

.unmute-audio:hover {
  background-color: #44a2fc;
  opacity: 1;
}

.stop-screen-share {
  background-image: url("./assets/icon-share.png");
}

.stop-screen-share:hover {
  background-color: #44a2fc;
  opacity: 1;
}

.start-screen-share {
  background-image: url("./assets/icon-share.png");
}

.start-screen-share:hover {
  background-color: #44a2fc;
  opacity: 1;
}

.show-profile {
  background-image: url("./assets/icon-text.png");
}

.show-profile:hover {
  opacity: 1;
  background-image: url("./assets/icon-text-actived.png");
  background-color: #44a2fc;
}

.back-btn {
  background-size: 32px;
  background-image: url("./assets/icon-back.png");
  background-repeat: no-repeat;
  top: 1rem;
  height: 32px;
  position: absolute;
  width: 32px;
  left: 1rem;
  cursor: pointer;
  z-index: 2;
}

.back-btn:hover {
  background-image: url("./assets/icon-back-hover.png");
}

.setting-btn {
  background-size: 32px;
  background-image: url("./assets/icon-setting.png");
  background-repeat: no-repeat;
  top: 1rem;
  height: 32px;
  position: absolute;
  width: 32px;
  right: 1rem;
  cursor: pointer;
  z-index: 2;
}

.setting-btn:hover {
  background-image: url("./assets/icon-setting-hover.png");
}

.role-container {
  display: flex;
  width: 300px;
  align-items: center;
  justify-content: space-around;
}

.role-item {
  cursor: pointer;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.inactive:hover .role-name {
  color: #44a2fc;
}

.inactive:hover .icon-audience {
  background-image: url("./assets/icon-audience.png");
}

.inactive:hover .icon-host {
  background-image: url("./assets/icon-host.png");
}

.inactive .role-name {
  padding-left: 5px;
  font-size: 14px;
  color: #eaeaea;
}

.active .role-name {
  padding-left: 5px;
  font-size: 14px;
  color: #44a2fc;
}

.custom-radio {
  position: relative;
  width: 14px;
  height: 14px;

  /* When the radio button is checked, add a blue background */
}

.custom-radio:hover [type="radio"] ~ .checkmark {
  background-color: #ccc;
}

.custom-radio [type="radio"]:checked ~ .checkmark {
  background-color: #2196f3;
}

.custom-radio [type="radio"],
.custom-radio .checkmark {
  width: 14px;
  height: 14px;
}

.custom-radio [type="radio"] {
  margin: 0;
  position: absolute;
  opacity: 0;
  cursor: pointer;
  z-index: 4;
}

.custom-radio [type="radio"]:checked ~ .checkmark::after {
  display: block;
}

.custom-radio .checkmark::after {
  content: "";
  position: absolute;
  display: none;
  top: 4px;
  left: 4px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: white;
}

.custom-radio .checkmark {
  top: 0;
  left: 0;
  background-color: #eee;
  border-radius: 50%;
}

.radio-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 75px;
  max-width: 75px;
  position: absolute;
  bottom: top;
  top: 132px;
  left: 19px;
}

.radio-row.host {
  left: 22px;
}

.radio-row.audience {
  left: 13px;
}

.error-icon {
  padding-right: 10px;
  width: 20px;
  height: 20px;
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-image: url("./assets/icon-error.png");
}

.cover-image {
  margin-top: 4rem;
  width: 180px;
  height: 180px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("./assets/logo-open-live.png");
}

.active .icon-audience {
  width: 100px;
  height: 118px;
  background-size: 100px 118px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("./assets/icon-audience.png");
}

.inactive .icon-audience {
  width: 100px;
  height: 118px;
  background-size: 100px 118px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("./assets/icon-audience-inactived.png");
}

.active .icon-host {
  width: 100px;
  height: 118px;
  background-size: 100px 118px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("./assets/icon-host.png");
}

.inactive .icon-host {
  width: 100px;
  height: 118px;
  background-size: 100px 118px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("./assets/icon-host-inactived.png");
}

.stream-container .stream-profile {
  top: 12px;
  left: 5px;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 6px;
  padding: 5px 5px;
}

.main-stream-profile {
  width: 150px;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 6px;
  padding: 5px 5px;
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 3;
  text-shadow: black 0.1em 0.1em 0.2em;
  font-size: 14px;
  color: #fff;
  right: 80px;
  top: 30px;
}

.stream-profile {
  left: 0;
  z-index: 2;
  color: #fff;
  opacity: 0.9;
  text-shadow: black 0.1em 0.1em 0.2em;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  position: absolute;
}
