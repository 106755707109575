.container {
  position: fixed;
  width: 20%;
  height: 50%;
  background-color: white;
  color: black;
  padding: 2rem;
  bottom: 0;
  left: 0;
  z-index: 2;
  border-radius: 15px;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.2);
  overflow-y: scroll;
  white-space: pre-wrap;
}
